<template>
  <div class="header">
    <div class="logo">
      <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/pc/logo.png" alt="">
    </div>
    <div class="menu" v-if="props.isPc">
      <div class="menuItem" @click="aboutMe">关于我们</div>
    </div>
    <div class="applyBtn" v-if="props.isPc" @click="goApply">申请试用</div>
    <div class="menuBtn" v-if="!props.isPc" @click="openNav">
    </div>
    <div v-if="data.dialogShow" class="navWrap">
      <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/mobile/nav-bg.png" alt="" class="bg">
      <div class="close" @click="closeNav">
        <img src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/mobile/close.png" alt="" >
      </div>
      <div class="wrap">
        <div class="navList">
          <div class="navItem" @click="aboutMe">关于我们</div>
        </div>
        <div class="applyBtnM" @click="goApply">申请试用</div>
      </div> 
    </div>
  </div>
</template>
<script setup>
  import {defineProps,reactive} from "vue";
  let props=defineProps({
    isPc:{
      type:Boolean,
      default:true
    }
  })
  let data=reactive({
    dialogShow:false,//弹窗显示隐藏
  })
  //关于我们
  const aboutMe=()=>{
    window.open('https://www.inspirecomgroup.com/')
  }
  //打开弹框
  const openNav=()=>{
    data.dialogShow = true;
  }
  //关闭弹框
  const closeNav=()=>{
    data.dialogShow = false;
  }
  //申请试用
  const goApply=()=>{
    window.open('https://aid.innatech.com.cn/#/');
  }
</script>
<style lang="scss" scoped>
.header{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.58rem;
  box-sizing: border-box;
  z-index: 10;
  background: #fff;
  .logo{
    height: 0.7rem;
    font-size: 0;
    img{
      height: 100%;
    }
  }
  .menu{
    flex: 1;
    width: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .menuItem{
      font-family: Source Han Sans CN;
      font-weight: 500;
      font-size: 15px;
      color: #222222;
      cursor: pointer;
    }
  }
  .applyBtn{
    width: 1.42rem;
    height: 0.36rem;
    text-align: center;
    line-height: 0.36rem;
    border-radius: 0.18rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 0.15rem;
    letter-spacing: 4px;
    color: #FFFFFF;
    margin-left: 0.65rem;
    background: linear-gradient(90deg,#6290f1,#6168e8);
    cursor: pointer;
  }
}
.navWrap{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
  box-sizing: border-box;
  padding-top:1.6rem;
  // transition: all 0.1s ease-in-out;
  background: #fff;
  .bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .close{
    position: absolute;
    top: 0.4rem;
    right: 0.31rem;
    width: 0.24rem;
    cursor: pointer;
    z-index: 10;
    font-size: 0;
    img{
      width: 100%;
    }
  }
  .wrap{
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
  }
  .navList {
    text-align: center;
    .navItem{
      font-family:'font-family: Source Han Sans CN Medium';
      font-weight: 500;
      font-size: 0.18rem;
      color: #222222;
      margin-bottom: 1rem;
    }
  }
  .applyBtnM{
    width: 2.15rem;
    height: 0.5rem;
    text-align: center;
    line-height: 0.5rem;
    border-radius: 0.25rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 0.18rem;
    color: #fff;
    letter-spacing: 4px;
    background: linear-gradient(90deg,#6290f1,#6168e8);
    cursor: pointer;
  }
}
@media screen and (max-width: 750px) {
  .header
  {
    height: 0.79rem;
    padding: 0 0.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo{
      height: 0.39rem;
    }
    .menuBtn{
      width: 0.23rem;
      height: 0.15rem;
      background: url('https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/innatechWebsie/mobile/menu.png');
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}
</style>